import { object } from 'yup';

import type { PromptExports } from './types';

// This is rendered whenever data from the server calls for a prompt to be
// displayed but the client can't find the right render code.
// The useShouldDisplay hook guarantees nothing is rendered.
const exports: PromptExports = {
  initialValues: {},
  validationSchema: object(),
  Component: () => null,
  useShouldDisplay: () => false,
};

export default exports;
