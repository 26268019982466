import type { PromptExports } from '../types';
import { initialValues, validationSchema, Component } from './PromptTou';

const exports: PromptExports = {
  initialValues,
  validationSchema,
  Component,
};

export default exports;
