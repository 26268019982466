import type { PromptExports } from '../types';
import {
  initialValues,
  validationSchema,
  Component,
} from './PromptDemoByOrganization';

const exports: PromptExports = {
  initialValues,
  validationSchema,
  Component,
};

export default exports;
