import { Formik, Form } from 'formik';
import { Modal } from '@perts/ui';
import { promptComponent } from './promptComponent';

export const RenderPrompt = ({
  label,
  onSubmit,
}: {
  label: string;
  onSubmit: (values: any) => void;
}) => {
  const { initialValues, useShouldDisplay, validationSchema, Component } =
    promptComponent(label);

  const shouldDisplay = useShouldDisplay();

  if (shouldDisplay === false) {
    return null;
  }

  return (
    <Modal>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await onSubmit(values);
        }}
      >
        <Form>
          <Component />
        </Form>
      </Formik>
    </Modal>
  );
};
