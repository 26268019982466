import { object } from 'yup';

import type { PromptExports } from '../types';
import { initialValues, Component } from './PromptElevate23GroupReports';

const exports: PromptExports = {
  initialValues,
  validationSchema: object(),
  Component,
};

export default exports;
